<template>
    <div class="evalution-detail-component" v-loading="userInfoLoading">
      <div class="card-container">
        <div class="main-title">评价信息</div>
        <div class="detail-one">
          <div class="detail-label">服务项目</div>
          <div class="detail-value">{{ userInfoDetail.gradeTypeStr || "-" }}</div>
        </div>
        <div class="detail-one">
          <div class="detail-label">评价时间</div>
          <div class="detail-value">
            {{ userInfoDetail.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </div>
        </div>
        <div class="detail-one">
          <div class="detail-label">整体满意度</div>
          <div class="detail-value">{{ userInfoDetail.gradeNum }}</div>
        </div>
        <!-- <div class="detail-one">
          <div class="detail-label">整体评价</div>
          <div class="detail-value">{{ userInfoDetail.description || "-" }}</div>
        </div> -->
        <div
          class="detail-one"
          v-for="(item, index) in userInfoDetail.gradeInfoVOList"
          :key="index"
        >
          <div class="detail-label">{{ item.gradeName }}</div>
          <div class="detail-value">{{ item.description || item.gradeNum }}</div>
        </div>
      </div>
      <div class="card-container">
        <div class="main-title">用户信息</div>
        <div class="detail-one">
          <div class="detail-label">微信昵称</div>
          <div class="detail-value">{{ userInfoDetail.wechatName || "-" }}</div>
        </div>
        <div class="detail-one">
          <div class="detail-label">真实姓名</div>
          <div class="detail-value">
            {{ userInfoDetail.gradeUserName || "-" }}
          </div>
        </div>
        <div class="detail-one">
          <div class="detail-label">企业名称</div>
          <div class="detail-value">
            {{ userInfoDetail.enterpriseName || "-" }}
          </div>
        </div>
        <div class="detail-one">
          <div class="detail-label">企业位置</div>
          <div class="detail-value">{{ userInfoDetail.positionName || "-" }}</div>
        </div>
        <div class="detail-one">
          <div class="detail-label">手机号码</div>
          <div class="detail-value">{{ userInfoDetail.gradeUserPhone }}</div>
        </div>
      </div>
      <div class="button-line">
        <el-button @click="$emit('close')">关闭</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import { querydetailsById } from "@/api/ruge/jwPark/userEvalution";
  export default {
    name: "evalutionDetailComponent",
    props: {
      dataset: {
        type: Object,
        default() {
          return {
            gradeInfoVOList: [],
          };
        },
      },
    },
    watch: {
      dataset: {
        handler(datas) {
          if (datas && datas.id) {
            this.getDetails(datas);
          }
        },
        deep: true,
        immediate: true,
      },
    },
    data() {
      return {
        userInfoLoading: false,
        userInfoDetail: {
          gradeInfoVOList: [],
        },
      };
    },
    methods: {
      getDetails({ id }) {
        this.userInfoLoading = true;
        querydetailsById({ id })
          .then((res) => {
            if(res.gradeNum == '1') {
              res.gradeNum = '很糟心'
            } else if(res.gradeNum == '2') {
              res.gradeNum = '一般般'
            } else if(res.gradeNum == '3') {
              res.gradeNum = '满意'
            } else if(res.gradeNum == '4') {
              res.gradeNum = '超级棒'
            }
            this.userInfoDetail = res;
          })
          .finally(() => {
            this.userInfoLoading = false;
          });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .evalution-detail-component {
    .card-container {
      background: #f8f8f8;
      border-radius: 4px;
      padding: 30px;
      .main-title {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
      }
      .detail-one {
        display: inline-block;
        width: 50%;
        margin-top: 15px;
        & div {
          line-height: 25px;
        }
        .detail-label {
          font-weight: 300;
          color: #000000;
          font-size: 12px;
        }
        .detail-value {
          font-weight: 500;
          color: #000000;
          font-size: 14px;
        }
      }
    }
    .card-container + .card-container {
      margin-top: 20px;
    }
    .button-line {
      margin: 20px 0;
      text-align: right;
    }
  }
  </style>